import React, { Component } from "react";
import WrapperModal from "../../_wrapper.modal";
import { ModalBody, ModalHeader } from "reactstrap";
import { ModalToggle } from "redux/actions/modal.action";
import connect from "react-redux/es/connect/connect";
import { noData, parseToRp, rmSpaceToStrip, toDate } from "../../../../../helper";
import HeaderDetailCommon from "../../../common/HeaderDetailCommon";
import TableCommon from "../../../common/TableCommon";
class DetailPromo extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  toggle(e) {
    e.preventDefault();
    const bool = !this.props.isOpen;
    this.props.dispatch(ModalToggle(bool));
  }

  render() {
    const snap = this.props.detail;
    const { detail } = snap;
    const head = [
      { rowSpan: 2, label: "No", className: "text-center", width: "1%" },
      { colSpan: 2, label: "Barang" },
      { rowSpan: 2, label: "Harga jual" },
      { rowSpan: 2, label: "Diskon" },
      { rowSpan: 2, label: "Min QTY" },
      { rowSpan: 2, label: "Min Transaksi" },

    ];
    const rowSpan = [{ label: "Kode" }, { label: "Nama" }];
    return (
      <WrapperModal isOpen={this.props.isOpen && this.props.type === "detailPromo"} size="lg">
        <ModalHeader toggle={this.toggle}>Detail Promo</ModalHeader>
        <ModalBody>
          <HeaderDetailCommon
            md="col-md-6"
            data={[
              { title: "ID Promo", desc: snap.id_promo },
              { title: "Catergory", desc: snap.category },
              { title: "Catatan", desc: snap.keterangan },
              { title: "Tanggal Mulai", desc: snap.daritgl },
              { title: "Tanggal Selesai", desc: snap.sampaitgl},



            ]}
          />
          <TableCommon
            head={head}
            rowSpan={rowSpan}
            renderRow={
              typeof detail === "object"
                ? detail.length > 0
                  ? detail.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="middle nowrap text-center">{i + 1}</td>
                          <td className="middle nowrap">{v.kd_brg}</td>
                          <td className="middle nowrap">{v.nm_brg}</td>
                          <td className="middle nowrap text-right">{parseToRp(v.hrg_jual)}</td>
                          <td className="middle nowrap text-right">{parseToRp(v.diskon)}</td>
                          <td className="middle nowrap text-right">{parseToRp(v.min_qty)}</td>
                          <td className="middle nowrap text-right">{parseToRp(v.min_trx)}</td>

                        </tr>
                      );
                    })
                  : noData(head.length)
                : noData(head.length)
            }
            
          />
        </ModalBody>
      </WrapperModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.modalReducer,
    type: state.modalTypeReducer,
  };
};
export default connect(mapStateToProps)(DetailPromo);
