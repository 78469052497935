export const linkDashboard='/';
// ###################################### DAFTAR LINK MENU MASTERDATA ##############################//
export const linkBarang = `/masterdata/barang`;
export const linkDepartment = "/masterdata/department";
export const linkSupplier = "/masterdata/supplier";
export const linkCustomer = "/masterdata/customer";
export const linkKas = "/masterdata/kas";
export const linkSales = "/masterdata/sales";
export const linkBank = "/masterdata/bank";
export const linkPromo = "/masterdata/promo";
export const linkPrinter = "/masterdata/printer";
export const linkPaket = "/masterdata/paket";
export const linkRak = "/masterdata/rak";
export const linkArea = "/masterdata/area";
export const linkMeja = "/masterdata/meja";
// ###################################### DAFTAR LINK MENU PRODUKSI ##############################//
export const linkProduksi = "/produksi";
// ###################################### DAFTAR LINK MENU INVENTORY ##############################//
export const linkAdjustment = "/inventory/adjustment";
export const linkDeliveryNote = "/inventory/delivery-note";
export const linkAlokasi = "/inventory/alokasi";
export const linkApprovalMutasi = "/inventory/approval-mutasi";
export const linkOpnameTransaksi = "/inventory/opname/transaksi";
export const linkOpnameApproval = "/inventory/opname/approval";
export const linkPengirimanPacking = "/inventory/pengiriman/packing";
export const linkPengirimanExpedisi = "/inventory/pengiriman/expedisi";
export const linkMutasiJualBeliApproval = "/inventory/mutasi-jual-beli/approval";
export const linkMutasiJualBeliBayar = "/inventory/mutasi-jual-beli/bayar";
// ###################################### DAFTAR LINK MENU PEMBELIAN ##############################//
export const linkPurchaseOrder = "/pembelian/purchase-order";
export const linkReceivePembelian = "/pembelian/receive-pembelian";
export const linkReturTanpaNota = "/pembelian/retur-tanpa-nota";
// ###################################### DAFTAR LINK MENU TRANSAKSI ##############################//
export const linkTransaksiBarang = "/transaksi/barang";
export const linkTransaksiKas = "/transaksi/kas";
// ###################################### DAFTAR LINK MENU PEMBAYARAN ##############################//
export const linkPembayaranHutang = "/pembayaran/hutang";
export const linkPembayaranPiutang = "/pembayaran/piutang";

// ###################################### DAFTAR LINK MENU REPORT ##############################//
export const linkReportClosing = "/report/closing";
export const linkReportKas = "/report/kas";
export const linkReportLabaRugi = "/report/laba_rugi";
export const linkReportProduksi = "/report/produksi";
// ************************************** REPORT PENJUALAN ************************************//
export const linkReportArsipPenjualan = "/report/penjualan/arsip-penjualan";
export const linkReportArsipReturPenjualan = "/report/penjualan/arsip-retur-penjualan";
export const linkReportArsipPenjualanByCustomer = "/report/penjualan/arsip-penjualan-by-customer";
export const linkReportArsipPenjualanBySales = "/report/penjualan/arsip-penjualan-by-sales";
export const linkReportArsipPenjualanByBarang = "/report/penjualan/arsip-penjualan-by-barang";
export const linkReportOmsetPenjualan = "/report/penjualan/omset-penjualan";
export const linkReportOmsetPenjualanByPeriode = "/report/penjualan/omset-penjualan-by-periode";
export const linkReportArsipPenjualanByKelompokBarang = "/report/penjualan/penjualan-by-kelompok-barang";
// ************************************** REPORT INVENTORY ************************************//
export const linkReportStock = "/report/inventory/stock";
export const linkReportNilaiPersediaan = "/report/inventory/nilai-persediaan";
export const linkReportAdjustment = "/report/inventory/adjustment";
export const linkReportAlokasi = "/report/inventory/alokasi";
export const linkReportDeliveryNote = "/report/inventory/delivery-note";
export const linkReportOpname = "/report/inventory/opname";
export const linkReportMutasi = "/report/inventory/mutasi";
export const linkReportAlokasiTransaksi = "/report/inventory/alokasi-transaksi";
export const linkReportPacking = "/report/inventory/packing";
export const linkReportExpedisi = "/report/inventory/expedisi";
// ************************************** REPORT PEMBELIAN ************************************//
export const linkReportPurchaseOrder = "/report/pembelian/purchase-order";
export const linkReportReceive = "/report/pembelian/receive";
export const linkReportPembelianBySupplier = "/report/pembelian/pembelian-by-supplier";
export const linkReportReturPembelian = "/report/pembelian/retur-pembelian";
// ************************************** REPORT PEMBAYARAN ************************************//
export const linkReportHutang = "/report/pembayaran/hutang";
export const linkReportPiutang = "/report/pembayaran/piutang";
// ************************************** REPORT PEMBAYARAN ************************************//
export const linkReportLogTransaksi = "/report/log/transaksi";
export const linkReportLogAktifitas = "/report/log/aktifitas";
// ###################################### DAFTAR LINK MENU CETAK BARCODE ##############################//
export const linkCetakBarcode = "/cetak-barcode";
// ###################################### DAFTAR LINK MENU PENGATURAN ##############################//
export const linkPengaturanUmum = "/pengaturan/umum";
export const linkPengaturanPengguna = "/pengaturan/pengguna";
export const linkPengaturanLokasi = "/pengaturan/lokasi";
// ###################################### DAFTAR LINK MENU PENGATURAN ##############################//
export const linkReportAlokasi3ply = "/report/inventory/alokasi/nota3ply/";
