import React, { Component } from "react";
import Layout from "../../Layout";
import connect from "react-redux/es/connect/connect";
import { FetchPromo, FetchPromoKategori } from "redux/actions/masterdata/promo/promo.action";
import { ModalToggle, ModalType } from "redux/actions/modal.action";
import moment from "moment";
import Paginationq from "helper";
import FormPromo from "components/App/modals/masterdata/promo/form_promo";
import UpdatePromo from "components/App/modals/masterdata/promo/update_promo";
import DetailPromo from "components/App/modals/masterdata/promo/detail_promo";

import { HEADERS } from "redux/actions/_constants";
import { deletePromo, FetchPromoDetail } from "redux/actions/masterdata/promo/promo.action";
import { FetchGroupProduct } from "redux/actions/masterdata/group_product/group_product.action";
import { FetchSupplierAll } from "redux/actions/masterdata/supplier/supplier.action";
import { FetchAllLocation } from "redux/actions/masterdata/location/location.action";
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import Noimage from "assets/default.png";
import HeaderReportCommon from "../../common/HeaderReportCommon";
import TableCommon from "../../common/TableCommon";
import ButtonActionCommon from "../../common/ButtonActionCommon";
import DetailReportRetur from "../../modals/report/purchase/retur/detail_report_retur";
import { CURRENT_DATE, DEFAULT_WHERE, generateNo, getFetchWhere, getPeriode, noData, parseToRp, rmSpaceToStrip, toDate } from "../../../../helper";


class Promo extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.detailModal = this.detailModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.state = {
      detail: {},
      lokasi_data: [],
      kategori_data: [],
      isModalForm: false,
      any: "",
    };

    // this.handlePagin = this.handlePagin.bind(this);
  }

  handlePageChange(pageNumber) {
    this.handleService(this.state.any, pageNumber);
  }
  handleService(any, page) {
    let where = `page=${page}`;
    if (any !== "") where += `&q=${any}`;
    this.setState({ where: where });
    this.props.dispatch(FetchPromo(where));
  }
  componentWillUnmount() {
    this.setState({ isModalForm: false });
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      lokasi_data: nextProps.lokasi.data,
      kategori_data: nextProps.promo_kategori,
    });
  };
  componentWillMount() {
    this.props.dispatch(FetchPromoKategori());
    this.handleService("", 1);
    this.props.dispatch(FetchAllLocation());
  }

  detailModal(e, id) {
    e.preventDefault();
    // const bool = !this.props.isOpen;
    this.props.dispatch(FetchPromoDetail(id));
    // this.props.dispatch(ModalToggle(bool));
    this.props.dispatch(ModalType("detailPromo"));
    let state = { isModalForm: true };
    this.setState(state);
  }

  updateModal(e, id) {
    e.preventDefault();
    const bool = !this.props.isOpen;
    this.props.dispatch(ModalToggle(bool));
    this.props.dispatch(FetchPromoDetail(id));
    this.props.dispatch(ModalType("updatePromo"));
    this.props.dispatch(FetchSupplierAll());
    this.props.dispatch(FetchGroupProduct(1, "", 100)); 
    let state = { isModalForm: true };
    this.setState(state);
  }

  toggleModal(e) {
    e.preventDefault();
    const bool = !this.props.isOpen;
    this.props.dispatch(ModalToggle(bool));
    this.props.dispatch(ModalType("formPromo"));
    this.props.dispatch(FetchSupplierAll());
    this.props.dispatch(FetchGroupProduct(1, "", 100));
    // if (res !== null) {
    //   this.props.dispatch(FetchPromoDetail(res.id));
    // }
    let state = { isModalForm: true };
    this.setState(state);
  }


  // handlePagin(param) {
  //   this.handleService(this.state.any, param);
  // }
  handleSearch(event) {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    let any = data.get("any");
    this.handleService(any, 1);
  }

  handleDelete(e, id) {
    e.preventDefault();
    this.props.dispatch(deletePromo(id));
  }

  render() {
    const { per_page, last_page, current_page, data, total } = this.props.promo;
    // const { startDate, endDate, column_data, isModalDetail } = this.state;

    const head = [
      { rowSpan: 2, label: "No", className: "text-center", width: "1%" },
      { rowSpan: 2, label: "#", className: "text-center", width: "1%" },
      { rowSpan: 2, label: "ID Promo", width: "1%" },
      { rowSpan: 2, label: "Kategori", width: "1%" },
      { colSpan: 2, label: "Periode", width: "1%" },
      { rowSpan: 2, label: "Lokasi", width: "1%" },
      { rowSpan: 2, label: "Member", width: "1%" },
      { rowSpan: 2, label: "Catatan" },
    ];
    const rowSpan = [{ label: "Tanggal Mulai" }, { label: "Tanggal Selesai" }];
    // const {
    //   current_page,
    //   data,
    //   // from,
    //   // last_page,
    //   per_page,
    //   // to,
    //   total,
    // } = this.props.promo;
    // const columnStyle = {verticalAlign: "middle", textAlign: "center",};
    console.log(this.state);
    return (
        <Layout page="Promo">
        <form onSubmit={this.handleSearch} noValidate>
          <div className="row">
            <div className="col-10 col-xs-10 col-md-11">
              <div className="row">
                <div className="col-md-4">
                  <div className="input-group input-group-sm">
                    <input
                      type="search"
                      name="any"
                      className="form-control form-control-sm"
                      placeholder="cari berdasarkan Catatan"
                      value={this.state.any}
                      onChange={(e) => {
                        this.setState({ any: e.target.value });
                      }}
                    />
                    <span className="input-group-append">
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-2 col-md-1 text-right">
              <div className="form-group">
                <button type="button" onClick={(e) => this.toggleModal(e)} className="btn btn-primary">
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
        <TableCommon
          head={head}
          rowSpan={rowSpan}
 
          renderRow={
            typeof data === "object"
              ? data.length > 0
                ? data.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center middle nowrap">{generateNo(i, current_page)}</td>
                        <td className="middle nowrap text-center">
                        <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    caret
                                    style={{
                                      background: "",
                                      border: "none",
                                    }}
                                  >
                                    <i className="zmdi zmdi-more-vert" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                  <DropdownItem onClick={(e) => this.detailModal(e, v.id_promo)}>
                                      <i className="ti-book-alt" /> Detail
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => this.updateModal(e, v.id_promo)}>
                                      <i className="ti-pencil-alt" /> Edit
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => this.handleDelete(e, v.id_promo)}>
                                      <i className="ti-trash" /> Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </td>
                        <td className="middle nowrap">{v.id_promo}</td>
                        <td className="middle nowrap">{v.category}</td>
                        <td className="middle nowrap">{v.periode === "1" ? "-" : moment(v.daritgl).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td className="middle nowrap">{v.periode === "1" ? "-" : moment(v.sampaitgl).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td className="middle nowrap">{v.lokasi}</td>
                        <td className="middle nowrap">{v.member === "0" ? "-" : v.member}</td>
                        <td className="middle nowrap">{v.keterangan.length > 20 ? `${v.keterangan.substring(0, 20)} ...` : v.keterangan}</td>
                      </tr>
                    );
                  })
                : noData(head.length + rowSpan.length)
              : noData(head.length + rowSpan.length)
          }
        //   footer={[
        //     {
        //       data: [
        //         { colSpan: 7, label: "Total perhalaman", className: "text-left" },
        //         { colSpan: 1, label: parseToRp(totalQtyPerHalaman) },
        //         { colSpan: 1, label: parseToRp(totalReturPerHalaman) },
        //         { colSpan: 2, label: "" },
        //       ],
        //     },
        //   ]}
        />
        {<div style={{ marginTop: "20px", float: "right" }}>
          <Paginationq current_page={current_page} per_page={per_page} total={total} callback={this.handlePageChange.bind(this)} />
        </div> }
         {this.props.isOpen && this.state.isModalForm ? (
          <DetailPromo detail={this.props.promo_detail}  />
        ) : null}

        {this.props.isOpen && this.state.isModalForm ? (
          <FormPromo kategori={this.props.promo_kategori} kel_barang={this.props.kel_barang} supplier={this.props.supplier} lokasi={this.props.lokasi} />
        ) : null}
        {this.props.isOpen && this.state.isModalForm ? (
          <UpdatePromo detail={this.props.promo_detail} kategori={this.props.promo_kategori} kel_barang={this.props.kel_barang} supplier={this.props.supplier} lokasi={this.props.lokasi} />
        ) : null}
        
      </Layout>
     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.modalReducer,
    authenticated: state.auth,
    promo: state.promoReducer.data,
    promo_kategori: state.promoReducer.data_kategori,
    promo_detail: state.promoReducer.detail,
    isLoading: state.promoReducer.isLoading,
    auth: state.auth,
    kel_barang: state.groupProductReducer.data,
    supplier: state.supplierReducer.dataSupllier,
    lokasi: state.locationReducer.allData,
  };
};

export default connect(mapStateToProps)(Promo);
